/* CMS Master */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Satisfy&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
body {
  font-family: Lato, sans-serif;
}

p,
a,
span,
label,
input,
tr,
td,
button,
textarea,
select,
b,
small {
  font-family: Lato, sans-serif;
  /* color: #414141; */
}

.form_border {
  border: 1px solid #122d59;
  border-radius: 20px;
}

.form-check-input:checked {
  background-color: #122d59;
  border-color: #122d59;
  box-shadow: 0 0 0 0.2rem rgba(40, 80, 167, 0.25);
}

.form-control:focus {
  border-color: #122d59;
  box-shadow: 0 0 0 0.2rem rgba(40, 80, 167, 0.25);
}

.form-select:focus {
  border-color: #122d59;
  box-shadow: 0 0 0 0.2rem rgba(40, 80, 167, 0.25);
}

select option:hover {
  background-color: #122d59;
  color: #fff;
}

.heading_color {
  color: #444;
}

.form_border div>p {
  font-size: 14px;
}

.location_border {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.table_border {
  border: 1px solid #122d59;
}

.Status_button i {
  color: #122d59;
}

.Status_button {
  background-color: transparent !important;
  padding: 0px 0px;
  border: transparent;
}

.Status_button:focus,
.Status_button:hover {
  box-shadow: none !important;
  transition: all 0.8s;
  background-color: transparent !important;
}

.Status_button:focus i,
.Status_button:hover i {
  color: #fcc101 !important;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

thead button.ascending::after {
  content: '\21E9';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: ' \21E7';
  display: inline-block;
  margin-left: 1em;
}


.table-hover tbody tr:hover td {
  background-color: lightyellow;
}


.select_dropdown {
  font-size: 14px;
}

.css-1pahdxg-control,
.css-ve73ta-control,
.css-t3ipsp-control {
  border-color: #122d59 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 80, 167, 0.25) !important;
}

/* .css-b62m3t-container {
  font-family: 'Poppins', sans-serif;
} */

.table-scrollable {
  overflow-x: auto;
  margin: auto;
}

.rdt_TableCell {
  color: #414141 !important;
  font-size: 15px !important;
}

.rdt_TableCol {
  font-size: 1rem !important;
}

.gnaqej:focus {
  border: 1px solid #122d59;
}

.bvxQGL:hover:not(:disabled) {
  background-color: #122d59 !important;
}

.bvxQGL:hover:not(:disabled) svg {
  fill: #fff;
}

.forgotpsw {
  /* color: blue; */
  color: #122d59;
}

.forgotpsw:hover {
  /* color: #122d59; */
  color: blue;
}

.forgot_Button {
  background-color: transparent;
  border: none;
}

/* .clearable-input {
  position: relative;
  display: inline-block;
}

.clearable-input>input {
  padding-right: 1.4em;
}

.clearable-input>[data-clear-input] {a
  display: none;
  position: absolute;
  top: 4px;
  right: 0;
  font-weight: bold;
  font-size: 1.4em;
  padding: 0 0.2em;
  line-height: 1em;
  cursor: pointer;
}

.clearable-input>input::-ms-clear {
  display: none;
} */

/* .fa-file-pdf-o,
.fa-file-excel-o {
  cursor: pointer;
} */

.bi-star-fill {
  color: #fcc101 !important;
}

/* responsive */

@media(max-width:991px) {
  .iieSkk {
    min-width: 36% !important;
  }

  .gCxdUn {
    min-width: 20% !important;
  }

  .kDRFRh {
    min-width: 20% !important;
  }

  .iWutVk {
    min-width: 5% !important;
  }
}

.required::after {
  content: "*";
  color: red;
}

.btn_add::-webkit-file-upload-button {
  display: none;
}

/* package master According*/
.accordion-button:not(.collapsed) {
  color: #122d59;
  background-color: #e7f3ffd1;
  /* box-shadow: inset 0 0 0 0.2rem rgba(167, 40, 40, 0.25); */
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border: 1px solid #122d59;
  border-color: 0 0 0 0.2rem rgba(40, 80, 167, 0.25);
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem rgba(167, 40, 40, 0.25); */
  box-shadow: none;
}

.border_set {
  border: 1px solid #122d59;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23122d59'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23122d59'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-body {
  background-color: #e7f1ff3d;
  border-left: 1px solid #122d59;
  border-right: 1px solid #122d59;
  border-bottom: 1px solid #122d59;
}

/* .accordion-item{
  border-color: #f6c6c6;
} */
.accordion-button {
  background-color: #e7f1ff3d;
  border: 1px solid #122d59;
}

/* .accordion-button:not(.collapsed)::after .accordion-header{
  border-color: #122d59;
} */
/* pack css */
section.package-detail .service-icon ul {
  padding: 0;
  margin: 0;
  text-align: right;
}

section.package-detail .service-icon li {
  background: #122d59;
  /* color: #fff; */
  margin: 0 5px;
  padding: 14px 15px;
  border-radius: 50%;
  line-height: 0;
}

section.package-detail .service-icon i {
  color: #fff;
  font-size: 24px;
}

section.package-detail .package-inner {
  border: 1px solid #ccc;
}

i.fa.fa-hand-lizard-o {
  transform: rotate(103deg);
  color: #9d9a9a;
  font-size: 14px;
}

span.border_top {
  width: 50px;
  height: 1px;
  background: #a5a3a3;
  display: block;
}

.flight1 li {
  text-align: center;
  font-size: 15px;
}

.date p {
  line-height: 1;
  font-size: 13px;
}

.hetel-box,
.itinerary-box,
.Inclusions-box,
.exclusions-box {
  margin: 5px;
  padding: 10px;
  background: #f2f2f2;
}

span.fs-10.mb-2 {
  font-size: 11px;
}

.fs-8 {
  font-size: 14px;
}

.hotel1 span.border_top {
  width: 30px;
}

.hotel-des {
  max-width: 150px;
}

.hotel1 i.fa.fa-calendar {
  color: #fcc101;
}

div#summery li {
  font-size: 12px;
  padding: 0 10px;
}

span.border_top {
  width: 15px;
}

.summery-title {
  color: #000;
  font-weight: 500;
  margin-left: 10px;
}

.booking-summery.mt-5 {
  padding: 20px;
  background: #efeded;
  max-height: 300px;
  overflow-y: scroll;
}

.booking-summery button {
  border: none;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  background: #efeded;
  width: 100%;
  text-align: left;
  position: sticky;
  top: -20px;
  padding: 10px;
}

div#summery1 {
  display: none;
}

.container-fluid .d-flex {
  background: none !important;
}

/* .bnAwAJ{
  max-width: 170px ;
} */
/* .fa-edit,
.fa-eye {
  cursor: pointer;
} */

.rdt_TableRow>div:nth-child(n-3) {
  /* float: left; */
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
}

.rdt_TableHeadRow>div:nth-child(n-3) {
  /* float: left; */
  padding-left: 5px;
  padding-right: 5px;
  max-width: 570px !important;
}

.bnAwAJ:nth-last-child(-n + 2) {
  justify-content: center;
}

.rdt_TableRow>div:nth-last-child(1) {
  right: 0;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  padding-left: 1px;
  padding-right: 1px;
  justify-content: center;
}

.rdt_TableHeadRow>div:nth-last-child(1) {
  right: 0;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  padding-left: 1px;
  padding-right: 1px;
  justify-content: center;
}

.rdt_TableRow>div:nth-last-child(2) {
  right: 44px;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
}

.rdt_TableHeadRow>div:nth-last-child(2) {
  right: 44px;
  float: right;
  position: sticky;
  background-color: #fff;
  min-width: 44px !important;
  max-width: 44px !important;
  justify-content: center;
  padding-left: 1px;
  padding-right: 1px;
}

.rdt_TableRow:hover>div:nth-last-child(1) {
  background-color: #EEEEEE;
}

.rdt_TableRow:hover>div:nth-last-child(2) {
  background-color: #EEEEEE;
}

.defualt-unstyled ul {
  list-style-type: initial;
}

.defualt-unstyled li {
  list-style: inherit;
}

.defualt-unstyled td {
  border-width: 1px !important;
}

.wraptextcont {
  position: relative;
  /* word-break: break-all; */
  text-align: justify;
}

.inputgroupclr {
  background-color: transparent;
  border-right: none;
}

.prefix_div {
  position: relative;
}

.prefix {
  position: absolute;
  transform: translate(60%, 22%);
  /* display: flex; */
}

.cutoffinput {
  padding-left: 2rem;
}

@media (max-width: 991px) {
  .d_responsive {
    height: 100% !important;
    width: 50% !important;
  }
}

@media (max-width: 767px) {
  .d_responsive {
    width: 140% !important;
    height: 100% !important;
  }

  .day1-box ul {
    padding-left: 0;
  }
}

.img_thumb {
  width: 100%;
  object-fit: fill;
  height: 100px;
}

.img_thumbhotel {
  width: 100%;
  object-fit: fill;
  height: 70px;
}

.video_thumb {
  height: 70px !important;
}

.closebtnimg {
  position: absolute;
  transform: translate(0%, -30%);
  padding: 0;
}

.clrbutton {
  position: relative;
}

.clrbutton>input {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}

.clrbutton>input:placeholder-shown+button {
  opacity: 0;
  pointer-events: none;
}

.clrbutton>button {
  position: absolute;
  border: none;
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 15px;
  border-radius: 50%;
  right: 0;
  transform: translate(-75%, -160%);
  background: #ddd;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: .1s;
}

@media (max-width: 600px) {

  .table_scroll {
    overflow-x: scroll;
  }
}

/* tab section supplier start*/
.tab_section ul li a.active {
  /* background-color: #122d59 !important; */
  background-color: #122d59 !important;
  color: #fff !important;
  /* border-bottom: 3px solid #5d34af; */
}

.tab_section ul li a {
  background-color: #e9ecef !important;
  color: #122d59;
}

/* 
.tab_section ul li a:hover {
  color: #e68a8a;
} */

/* tab section supplier end */

.mobileprefix {
  position: absolute;
  font-size: 14px;
  transform: translate(60%, 36%);
  color: #6c757d !important;

}

.mobilecutoffinput {
  padding-left: 2.5rem !important;
}

/* InventoryManagement css start */
.tableborder_color {
  border-color: #122d59 !important;
}

.tableheaderborder_color {
  border-bottom: 2px solid #122d59 !important;
}

.inventorycolor {
  color: #122d59;
}

.tabspan_width {
  width: 30px;
  height: 30px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  margin: auto;
}

.inventory_headermodal {
  background-color: #122d59 !important;
  padding: .25rem 1rem .3rem 1rem;
  margin-top: 0;
}

.inventory_headermodal .inventory_title {
  color: #fff;
  text-align: center;
}

.inventory_headermodal .closebtn_position {
  margin: 0rem 0rem 0rem 0rem !important;
  padding: 0.5rem 0.5rem !important;
  opacity: 0.9 !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.over_table {
  overflow-x: scroll;
}

.table_body th {
  min-width: 175px;
}

.tableheaderborder_color th:first-child {
  min-width: 175px;
}

.tableheaderborder_color th {
  text-wrap: nowrap;
}

.bg_available {
  background-color: #24a40999 !important;
}

.bg_SoldOut {
  background-color: #ff572282 !important;
}

.bg_OnRequest {
  background-color: #edda328f !important;
}

.bg_BlackOut {
  background-color: #ff0000bf !important;
}

.spancolor_size {
  height: 13px;
  width: 13px;
}

.filter_section {
  display: flex;
  align-items: center;
  background-color: #EEEEEE;
  padding: 5px;
  border-radius: 50px;
  font-size: 13px;
}

.filter_buttonclose {
  color: white !important;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter_section:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.filter_section:hover .filter_buttonclose {
  transform: scale(1.1);
  /* font-size: 16px; */
  font-size: 1.06rem;
}

/* InventoryManagement css end */
/* User Admin css start */
.user_role .rdt_TableRow>div:nth-last-child(2) {
  right: auto !important;
  float: left !important;
  position: unset;
  background-color: transparent;
  min-width: auto !important;
  max-width: 100% !important;
  justify-content: start;
  padding-left: 1px;
  padding-right: 1px;
}

.user_role .rdt_TableHeadRow>div:nth-last-child(2) {
  right: auto !important;
  float: left;
  position: unset;
  background-color: #fff;
  min-width: auto !important;
  max-width: 100% !important;
  justify-content: start;
  padding-left: 1px;
  padding-right: 1px;
}

.admin_btn i {
  font-size: 18px;
  color: #122d59;
  transition: all 0.4s ease;
}

.rotet {
  transform: rotateZ(-180deg);
}

.set_disableclr:disabled {
  opacity: .55;
}

/* User Admin css end */

/* Holiday Booking List css start */

/* .holiday_container {
  height: 100% !important;
} */

.booking_bg_color {
  background-color: #eaeff7 !important;
}

.holiday_packages:hover {
  background-color: aliceblue !important;
}

.pointer_cursor {
  cursor: pointer !important;
}

.search_box {
  padding: 14px !important;
}

.searchBtn {
  position: absolute;
  top: 14px;
  right: 15px;
  transition: right 0.2s;
}

.package_details {
  font-size: 12px !important;
}

.confirmed_package_status {
  color: #008000 !important;
  background-color: #0080002d !important;
}

.pending_package_status {
  color: #b28514 !important;
  background-color: #ffd90043 !important;
}

.cancel_package_status {
  color: #c40202 !important;
  background-color: #ff19192a !important;
}

.Pending_Payment_package_status {
  color: #122d59 !important;
  background-color: #356ac029 !important;
}

/* Holiday Booking List css end */

/* Find Customer css start */

.newCustomerBtn {
  color: #074ea0 !important;
}

.create_customer_container {
  height: 85vh;
}


/* Find Customer css end */

/* Fixed and Customized Package css start */

.package_list ul li a {
  background-color: #fff !important;
}

/* Fixed and Customized Package css end */

/* progressbar design start */
.progress_bar {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}

.progress-step {
  text-align: center;
  position: relative;
}

.progress-step:before,
.progress-step:after {
  background-color: #0791be;
  content: "";
  height: 2px;
  position: absolute;
  z-index: 0;
  top: 8px;
}

.progress-step:after {
  left: 50%;
  width: 100%;
}

.progress-step:last-of-type.is-active:after {
  background-color: #0791be;
}

@media screen and (min-width: 640px) {
  .progress-step:first-of-type:before {
    right: 50%;
    width: 50%;
    background-color: white !important;
  }

  .first::before {
    right: 0% !important;
  }
}

@media screen and (max-width: 640px) {

  .progress-step:first-of-type:before,
  .progress-step:last-of-type:after {
    background-color: white !important;
  }
}

.progress-step:last-of-type:after {
  left: 50%;
  width: 0%;
}

.progress-step .step-count {
  background-color: #0791be;
  height: 16px;
  width: 16px;
  margin: 0 auto;
  border-radius: 50%;
  /* color: white; */
  line-height: 16px;
  z-index: 100;
  border: 3px solid white;
  /* font-size: 0.8rem; */
  font-weight: 500;
  outline: 1px solid #0791be;
  position: relative;
}

.one .step-count {
  margin: 0 auto auto auto;
}

/* .progress-step .step-count:before {
  counter-increment: step-count;
  content: counter(step-count);
} */

.progress-step.is-active .step-description {
  font-weight: 500;
}

.progress-step.is-active:after {
  background-color: #d9d9d9;
}

.progress-step.is-active~.progress-step .step-count {
  background-color: #d9d9d9;
  outline: 1px solid #d9d9d9;
}

.progress-step .step-description {
  color: #0791be;
}

.progress-step.is-active~.progress-step .step-description {
  color: #d9d9d9;
  /* outline: 1px solid #d9d9d9; */
}

.progress-step.is-active~.progress-step:after {
  background-color: #d9d9d9;
}

.step-description {
  font-size: 0.8rem;
}

/* progressbar design end */

/* Traveler Detail css start */

.book_now_btn {
  color: #FFF !important;
  /* background-color: #ff4400e7 !important; */
  background-color: #ef4e24 !important;
}

.lightblue_bg {
  background-color: #eaeff7 !important;
}

/* Traveler Detail css end */

/* Booking Summary css start */

.lightorange_bg {
  /* background-color: #ff440044 !important; */
  background-color: #feeeea !important;
}

.white_bg {
  background-color: #fff !important;
}

/* Booking Summary css end */

/* Fixed Package Detail css start */

.plus_minus_btn {
  text-align: center;
  font-size: 10px;
  font-weight: 900;
  height: 20px;
  width: 20px;
  color: #FFFFFF;
}

.counter_btns {
  border: 1px solid #122d59;
  padding: 0.2rem 0.5rem;
}

.customized_trip_switch .react-switch {
  margin-left: 0 !important;
}


/* Fixed Package Detail css end */

/* Customized Package Detail css start */

.editbtn {
  position: absolute;
  top: 8px;
  right: 10px;
  transition: right 0.2s;
}

.lightblue_color {
  color: #275daa !important;
}

.customized_itinerary_items .accordion-item {
  background-color: unset !important;
  border: none !important;
}

/* .customized_timepicker .ant-picker-input input::placeholder,
.ant-picker-suffix {
  color: #6c757d !important;
} */

.defo_border:hover {
  border: 1px solid #ced4da;
}

.ant-picker-focused {
  border-color: #122d59 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 80, 167, 0.25) !important;
}

.defo_border .ant-picker-suffix {
  color: #6c757d !important;
}

.defo_border input::placeholder {
  color: #6c757d !important;
}

/* Customized Package Detail css end */
.card{
  border-radius: 1rem !important;
}
.card-header:first-child {
  border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0;
}