.otp_mess {
    /* height: 22px; */
    color: green;
  }
  
  .custom_cancel_button {
   
    color: white; 
  }
  .custom_cancel_button:hover {
   
    background-color:transparent !important;
    color: white !important;
  }
  